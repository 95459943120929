import { setCookie } from '../../../../../common/js_helpers/dom_helpers';
import privacyPreferences from '../../../../../common/privacy/privacy_preferences';

interface FormCtaSavedContent {
  cta: FormCtaSavedCta[];
  placement: FormCtaSavedPlacement[];
}

interface FormCtaSavedCta {
  ctaID: number | string;
  listID: number | string;
}

interface FormCtaSavedPlacement {
  placementID: number | string;
  hideUntil: number;
}

class FormCtaStorage {
  private readonly CTA_SESSION_HIDDEN_DAYS: number = 7;

  private readonly LOCAL_STORAGE_KEY = 'flyptech-hub-%d';

  private readonly CTAS_COOKIE_NAME = 'flyptech-hub-%d-ctas';

  private readonly DEFAULT_STORAGE: FormCtaSavedContent = {
    cta: [],
    placement: [],
  };

  private readonly storageKey: string = '';

  private readonly cookieName: string = '';

  private readonly cta!: HTMLElement;

  private readonly hubId: string = '';

  private readonly ctaId: string = '';

  private readonly listId: string = '';

  private readonly placementId: string = '';

  public constructor(cta: HTMLElement | null) {
    if (!cta) return;

    this.cta = cta;
    this.ctaId = this.cta.dataset.id || '';
    this.hubId = this.cta.dataset.hubId || '';
    this.listId = this.cta.dataset.listId || '';
    this.placementId = this.cta.dataset.placementId || '';

    this.storageKey = this.LOCAL_STORAGE_KEY.replace('%d', this.hubId);
    this.cookieName = this.CTAS_COOKIE_NAME.replace('%d', this.hubId);
  }

  private get = (): FormCtaSavedContent => {
    const storage: string | null = localStorage.getItem(this.storageKey);
    return storage ? JSON.parse(storage) : this.DEFAULT_STORAGE;
  };

  private setLocalStorage = (savedContent: FormCtaSavedContent): void => {
    localStorage.setItem(this.storageKey, JSON.stringify(savedContent));
  };

  private setCookie = (ctas: FormCtaSavedCta[]): void =>
    setCookie(this.cookieName, JSON.stringify(ctas));

  private deleteCta = (): void => {
    const storage = this.get();
    const ctaIndex = this.findIndexForCta();
    if (ctaIndex > -1) {
      storage.cta.splice(ctaIndex, 1);
      this.setLocalStorage(storage);
      this.setCookie(storage.cta);
    }
  };

  private findIndexForCta = (): number => {
    const savedValue = this.get();
    const ctas = savedValue.cta;
    let matchedIndex = -1;

    ctas.forEach((cta, index) => {
      if (cta.ctaID === this.ctaId && cta.listID === this.listId) {
        matchedIndex = index;
      }
    });
    return matchedIndex;
  };

  private deletePlacement = (): void => {
    const storage = this.get();
    const placementIndex = this.findIndexForPlacement();
    if (placementIndex > -1) {
      storage.placement.splice(placementIndex, 1);
      this.setLocalStorage(storage);
    }
  };

  private findIndexForPlacement = (): number => {
    const savedValue = this.get();
    const placements = savedValue.placement;
    let matchedIndex = -1;

    placements.forEach((placement, index) => {
      if (placement.placementID === this.placementId) {
        matchedIndex = index;
      }
    });
    return matchedIndex;
  };

  private getExpiryDate = (): number => {
    const date: Date = new Date();
    return date.setDate(date.getDate() + this.CTA_SESSION_HIDDEN_DAYS);
  };

  // Public:
  // ---

  public setCta = (): void => {
    this.deleteCta();

    const storage = this.get();
    const savedCta = { ctaID: this.ctaId, listID: this.listId };
    storage.cta.push(savedCta);
    this.setLocalStorage(storage);

    if (privacyPreferences.isFunctionalityEnabled('UBERFLIP')) {
      this.setCookie(storage.cta);
    }
  };

  public showForPlacementId = (): boolean => {
    const storage = this.get();
    const matched = storage.placement.find(
      (placement: FormCtaSavedPlacement) => placement.placementID === this.placementId,
    );
    if (!matched) {
      return true;
    }

    const currentDate = new Date();
    const expiryDate = new Date(matched.hideUntil);
    const isExpired = currentDate > expiryDate;
    if (isExpired) {
      this.deletePlacement();
    }
    return isExpired;
  };

  public setPlacement = (): void => {
    this.deletePlacement();

    const storage = this.get();
    const savedPlacement = { hideUntil: this.getExpiryDate(), placementID: this.placementId };
    storage.placement.push(savedPlacement);
    this.setLocalStorage(storage);
  };
}

export default FormCtaStorage;
