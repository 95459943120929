declare global {
  interface Window {
    gtag: Function;
  }
}

interface GoogleAnalyticsEvent {
  category: string;
  action: string;
  label: string;
  code: string;
}

class GoogleAnalyticsService {
  private readonly isEnabled: boolean;

  public constructor() {
    this.isEnabled = window.gtag && typeof window.gtag === 'function';
  }

  public sendEvent = (event: GoogleAnalyticsEvent): void => {
    if (!this.isEnabled) {
      return;
    }

    const { category, action, label } = event;
    window.gtag('event', action, {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: category,
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: label,
    });
  };
}

export default GoogleAnalyticsService;
